import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { unavailableTime } from '@/utils'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: configRoutes()
})

const metaSeo = {
  ag_tgone_bet: {
    hotgame: {
      title: 'เกมนิยม - เว็บนอกแท้ศูนย์รวมเกมคาสิโนยอดนิยม อันดับ 1',
      description: 'ศูนย์รวมเกมคาสิโนยอดนิยม อันดับ 1 สล็อต คาสิโน บาคาร่า ยิงปลา ที่เดียวเล่นได้ทุกเกม',
      keywords: 'tgone,เว็บนอก,เว็บใหญ่,เว็บนอกแท้,เว็บนอกตรง'
    },
    casino: {
      title: 'คาสิโน - เกมคาสิโนออนไลน์เว็บใหญ่จากอเมริกาครบจบในเว็บเดียว',
      description: 'เกมคาสิโนออนไลน์ บาคาร่า เสือมังกร เกมคาสิโนครบจบในเว็บเดียว ',
      keywords: 'tgone.xyz,เว็บนอกของแท้,เว็บใหญ่จากอเมริกา,เว็บนอกแท้100'
    },
    slot: {
      title: 'สล็อต - เกมสล็อตออนไลน์เว็บนอกลิขสิทธิ์แท้ค่ายเกมชั้นนำยกมาให้ในที่เดียว',
      description: ' เกมสล็อตออนไลน์ค่ายเกมชั้นนำ pg joker habanero cq9 และอื่นๆอีกมากมาย ยกมาให้ในที่เดียว ',
      keywords: 'tgone,เว็บใหญ่สล็อต,เว็บนอกสล็อต,เว็บนอกลิขสิทธิ์แท้'
    },
    lotto: {
      title: 'หวย - หวยออนไลน์ยอดนิยม ไทย ฮานอย ยี่กี มีทุกหวยที่ต้องการ',
      description: 'หวยออนไลน์ยอดนิยม ไทย ฮานอย ยี่กี ไม่มีอั้นเลขมีทุกหวยที่ต้องการพร้อมจ่ายหนักไม่เหมือนที่อื่นๆ ',
      keywords: 'tgone,tgone หวย,หวยไทย,เล่นหวย,หวยรัฐบาล,หวยหุ้น,แทงหวย,แทงหวยไทย,หวยลาว'
    },
    sport: {
      title: 'กีฬา - Sport online เกมกีฬาชั้นนำแทงบอลออนไลน์กีฬาออนไลน์มาแรงอันดับ1 ',
      description: ' เกมกีฬาออนไลน์ชั้นนำ เว็บกีฬาออนไลน์ บอล บาส มวย แข่งม้า ทุกเกมกีฬาที่ต้องการ มาแรงที่สุดอันดับ1 ',
      keywords: 'tgone แทงบอลออนไลน์,แทงบอล,แทงบอลออนไลน์'
    },
    visual_sport: {
      title: 'กีฬาจำลอง - เกมโต๊ะกีฬาออนไลน์ tgone กีฬาออนไลน์ รวมเกมกีฬาครบวงจร',
      description: 'คาสิโนเกมโต๊ะกีฬาจำลองออนไลน์ บอล มวย บาส พร้อมระบบ AI อัพเกรดใหม่ล่าสุด ทั้งภาพและเสียงเหมือนลงไปเล่นเอง',
      keywords: 'tgone กีฬาออนไลน์, ufabet168,ufabet123,ufabet8'
    },
    fishing: {
      title: 'ยิงปลา - เกมยิงปลาออนไลน์fishing game ค่ายชั้นนำ มาพร้อมภาพและเสียง FULL HD',
      description: 'เกมยิงปลาออนไลน์ ศูนย์รวมเกมยิงปลาค่ายชั้นนำ มาพร้อมระบบ AI อัพเกรดใหม่ ยิงแตกง่ายกว่าเดิม 98 % ภาพและเสียง แบบFULL HD',
      keywords: 'tgone เกมยิงปลาออนไลน์,เกมยิงปลาออนไลน์,fishing game'
    },
    promotions: {
      title: 'โปรโมชั่น - เว็บพนันออนไลน์ครบวงจรกับโปรโมชั่นที่ให้มากกว่าที่อื่น',
      description: 'เว็บพนันออนไลน์ ศูนย์รวมโปรโมชั่นเอาใจนักพนันทั้งหลาย กับโปรโมชั่นที่ให้สิทธิประโยชน์กับผู้เล่นมาก',
      keywords: 'tgone โปรโมชั่น,โปรสล็อต,โปรบา,โปรบาคาร่า,โปรโมชั่นเว็บ,เครดิตฟรี'
    }
  },
  ag_tgone_club: {
    hotgame: {
      title: 'TGONE เว็บพนัน คาสิโนออนไลน์ เคดิตฟรี บริการ 24 ชั่วโมง',
      description: 'TGONE เปิดระบบใหม่เว็บใหญ่ให้บริการ คาสิโน หวย สล็อต กีฬา ครบจบในเว็บเดียว ติดต่อได้ทาง LINE @tg1.pro มาตราฐานระดับโลก ฝากถอน AUTO ไม่มีขั่นต่ำ',
      keywords: ''
    },
    casino: {
      title: 'คาสิโนออนไลน์',
      description: 'คาสิโน ออนไลน์ เว็บตรงใหม่ ใหญ่ เล่นง่ายจ่ายจริง พร้อมให้บริการเกมส์ไพ่ ครบวงจร เว็บอันดับ 1 ในไทยเปิดประสบการณ์เล่นคาสิโนใหม่ สมัครวันนี้เคดิตเพิ่ม ',
      keywords: ''
    },
    slot: {
      title: 'สล็อต เกมส์สสล็อตใหม่ ออนไลน์',
      description: 'สล็อตออนไลน์ ใหม่ระบบเกมส์สล็อตออนไลน์ มากกว่า 10 ค่าย ให้เลือกเล่น และ เรทแจ๊สพ็อตแตก อัดแน่น 100% พร้อมเกมส์สล็อตใหม่ 2024 มีระบบ AUTO ฝากถอนไม่มีขั่นต่ำ',
      keywords: ''
    },
    lotto: {
      title: 'หวยออนไลน์',
      description: 'ร่วมเดิมพนันหวยออนไลน์กับเว็บ TGONE ให้บริการหวย ทุกรูปแบบ หวยยี่กี หวยรัฐบาลไทย หวยลาว หวยฮานอย เป็นต้น พร้อมจ่ายในอัตรา ที่สูง 3 ตัวบาทละ 1000 และ 2 ตัวบาทละ 100 บริการ 24 ชั่วโมง',
      keywords: ''
    },
    sport: {
      title: 'เดิมพนันเกมส์แข่งกีฬา',
      description: ' วางเดิมพันเกมส์กีฬา ให้เลือกเล่น เว็บพนันที่เชื่อถือได้ ปลอดภัย มวย แทงบอล ไก่ชน และอื่นๆ รองรับฝากถอน AUTO ไม่มีขั่นต่ำ 10 บาท ก็เล่นได้ กับ TGONE เว็บอันดับหนึ่งเรื่องเดิมพันก๊ฬา ',
      keywords: ''
    },
    visual_sport: {
      title: 'กีฬาจำลอง Visual sport  ',
      description: 'เดิมพันเกมส์ กีฬาจำลอง หรือ Visual sport ได้แล้ววันนี้ กับเกมส์เดิมพนันกีฬาเสมือนจริง กับเกมส์ เบสบอลฮอกกี้ และ แข่งม้า  และ อื่นๆ',
      keywords: ''
    },
    fishing: {
      title: 'เกมส์ยิงปลา ได้โบนัสเพิ่ม',
      description: 'เกมส์ยิงปลา ได้ยิงปลาได้เงินจริง สมัครได้แล้ววันนี้ แตกง่าย แจกยิงฟรีมากสุด ฝากถอนออโต้ ฝากถอนไม่มีขั่นต่ำ ',
      keywords: ''
    },
    promotions: {
      title: 'โปรโมทชั่น TGONE ',
      description: 'ใหม่โปรโมทชั่น พิเศษ กับสมาชิกทุกท่าน กับ โปรวันเกิด และ โปรโมทชั่นบาคาร่า ผิด-ถูก 10 ไม้ติดต่อกัน หรือจะเป็น สเตปบอลตาย รับโบนัสฟรี รายละเอียดเพิ่มเติมสามารถเข้าอ่านได้ที่เว็บไชต์ tgone.club',
      keywords: ''
    }
  }
}

function configRoutes () {
  return [{
    path: '/',
    name: 'Main',
    // redirect: 'member/main',
    redirect: '/',
    component: () => import('@/pages/Main.vue'),
    children: [{
      path: 'member/main',
      name: 'hotgame',
      meta: {
        seoName: 'hotgame',
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/',
      name: 'hotgame',
      meta: {
        seoName: 'hotgame',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/member/welcome',
      name: 'hotgame',
      meta: {
        seoName: 'hotgame',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/play_lottery',
      name: 'hotgame',
      meta: {
        seoName: 'hotgame',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/authenticate',
      name: 'hotgame',
      meta: {
        seoName: 'hotgame',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'casino',
      name: 'casino',
      meta: {
        seoName: 'casino',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'visual_sport',
      name: 'visual_sport',
      meta: {
        seoName: 'visual_sport',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'slot',
      name: 'slot',
      meta: {
        seoName: 'slot',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'slot/:banner',
      name: 'games/slot',
      meta: {
        seoName: 'slot',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'sport',
      name: 'sport',
      meta: {
        seoName: 'sport',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'fishing',
      name: 'fishing',
      meta: {
        seoName: 'fishing',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'promotions',
      name: 'promotions',
      meta: {
        seoName: 'promotions',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'lotto',
      name: 'lotto',
      meta: {
        seoName: 'lotto',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      // Tg v4.0
      // component: () => import('@/pages/huayPage/huayPage.vue')
      // Tg v3.0
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    // {
    //   path: 'welcome',
    //   name: 'welcome',
    //   meta: {
    //     requiresAuth: false,
    //     isLottoPage: false,
    //     isGamePage: false
    //   },
    //   component: () => import('@/pages/welcomePage/welcomePage.vue')
    // },
    {
      path: 'welcome',
      name: 'hotgame',
      meta: {
        seoName: 'hotgame',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'hotgame',
      name: 'hotgame',
      meta: {
        seoName: 'hotgame',
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false,
        title: 'Amazing Title',
        description: 'Awesome Description',
        keywords: 'Awesome keywords'
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'login',
      name: 'welcome',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/welcomePage/welcomePage.vue')
    },
    {
      path: 'huay',
      name: 'huay',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/huayPage/huayPage.vue')
    },
    {
      path: 'lotto/:lottoType',
      name: 'lotto/:lottoType',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/huayPage/betPage.vue')
    },
    {
      path: 'setting/profile',
      name: 'profile',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/settingsProfilePage/settingsProfilePage.vue')
    },
    {
      path: 'forgetpassword',
      name: 'forgot-password',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/forgotPage/forgotPage.vue')
    },
    // {
    //   path: 'member/lottery',
    //   name: 'lottery',
    //   meta: {
    //     requiresAuth: true,
    //     isLottoPage: true,
    //     isGamePage: false
    //   },
    //   component: () => import('@/pages/lotteryPage/lotteryPage.vue')
    // },
    {
      path: 'member/lottery',
      name: 'lotto',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'member/result/all',
      name: 'result',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/resultPage/resultPage.vue')
    },
    // {
    //   path: 'member/lottery/lottoset',
    //   name: 'lottolist',
    //   meta: {
    //     requiresAuth: true,
    //     isLottoPage: false,
    //     isGamePage: false
    //   },
    //   component: () => import('@/pages/lottoSetPage/lottoSetPage.vue')
    // },
    {
      path: 'member/lottery/lottoset',
      name: 'lotto',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'member/lottery/lottoset/:id',
      name: 'lottoset/:id',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoSetPage/numberSetPage/numberSetPage.vue')
    },
    // For lottery result
    {
      path: 'member/lottery/result/:id',
      name: 'lottoticket/:id',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoTicketPage/numberTicketPage/numberTicketPage.vue')
    },
    {
      path: 'member/lottery/lottoset/:id/tang',
      name: 'lottoset/:id/tang',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lottoSetPage/numberSetTangPage/numberSetTangPage.vue')
    },
    {
      path: 'member/lottery/lottoset/:id/poy',
      name: 'lottoset/:id/tang',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lottoSetPage/numberSetPoyPage/numberSetPoyPage.vue')
    },
    // For lottery poy
    {
      path: 'member/lottery/poy/:id/',
      name: 'lottoset/poy/:id',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lottoTicketPage/numberTicketPoyPage/numberTicketPoyPage.vue')
    },
    {
      path: 'member/settings/bank/add',
      name: 'bankbook',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      // component: () => import('@/pages/bankBookPage/bankBookPage.vue')
      component: () => import('@/pages/bankBookPage/bankBookPage2.vue')
    },
    {
      path: 'member/settings/bank2/add',
      name: 'bankbook2',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/bankBookPage/bankBookPage.vue')
      // component: () => import('@/pages/bankBookPage/bankBookPage2.vue')
    },
    {
      path: 'member/request/all',
      name: 'memberrequest',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/requestCashPage/requestCashPage.vue')
    },
    {
      path: 'member/statement',
      name: 'statement',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/statementPage/statementPage.vue')
    },
    {
      path: 'member/games',
      name: 'games',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/index.vue')
    },
    {
      path: 'member/games/casino',
      name: 'games/casino',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/casino.vue')
    },
    {
      path: 'member/games/fisher',
      name: 'games/fisher',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/fisher.vue')
    },
    {
      path: 'member/games/sport',
      name: 'games/sport',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/sport.vue')
    },
    {
      path: 'member/games/slot/:banner',
      name: 'games/slot',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/slot.vue')
    },
    {
      path: 'member/games/history',
      name: 'games/history',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/games/history.vue')
    },
    {
      path: 'member/numbersets',
      name: 'numbersets',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/numberSetPage/numberSetPage.vue')
    },
    {
      path: 'member/numbersets/detail/:id',
      name: 'numbersets/detail',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/numberSetDetail/numberSetDetail.vue')
    },
    {
      path: 'member/numbersets/add',
      name: 'numberSetAddPage',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/numberSetAddPage/numberSetAddPage.vue')
    },
    {
      path: 'member/poy',
      name: 'poy',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/poyPage/poyPage.vue')
    },
    {
      path: 'member/poy/result/:id',
      name: 'poy/result',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/poyPage/poyResultPage.vue')
    },
    {
      path: 'member/lottery/government',
      name: 'lottery-government',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/govermentLottoPage/govermentLottoPage.vue')
    },
    {
      path: 'member/lottery/:id',
      name: 'lottery-by-id',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      // component: () => import('@/pages/lottoryBetPage/lottoBetPage.vue')
      component: () => import('@/pages/lottoTicketPage/numberTicketTangPage/numberTicketTangPage.vue')
    },
    {
      path: 'member/lottery/lotto/:id',
      name: 'lottery-government-by-id',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoBetPage/lottoBetPage.vue')
    },
    {
      path: 'member/lottery/lotto/result/:id',
      name: 'lotto-lottery-result',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/lottoResultPage/lottoResultPage.vue')
    },
    {
      path: 'member/lottery/yeeki/:catalog',
      name: 'member-lottery-yeekee',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/yeekeeLotteryPage/yeekeeLotteryPage.vue')
    },
    {
      path: 'member/lottery/yeeki/:catalog/:id',
      name: 'yeekee-lottery-by-bet',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoBetYeekiPage/lottoBetYeekiPage.vue')
    },
    {
      path: 'member/lottery/yeeki-fire/:catalog/:id',
      name: 'yeekee-lottery-by-bet-fire',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoBetYeekiPage/lottoBetYeekiFirePage/lottoBetYeekiFirePage.vue')

    },
    {
      path: 'member/lottery/yeeki-result/:catalog/:id',
      name: 'yeekee-lottery-result',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/yeekeeLotteryPage/yeekeeResultPage/yeekeeResultPage.vue')
    },
    {
      path: 'member/lottery/yeeki-result/:catalog/:id/:page',
      name: 'yeekee-lottery-result',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/yeekeeLotteryPage/yeekeeResultPage/yeekeeResultPage.vue')
    },
    {
      path: 'member/affiliate',
      name: 'affiliate',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/affiliatePage/affiliatePage.vue')
    },
    {
      path: 'member/affiliate/members',
      name: 'affiliate/members',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/affiliatePage/affiliateMemberPage.vue')
    },
    {
      path: 'member/affiliate/revenue',
      name: 'affiliate/revenue',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      // TODO: VUE_APP_CASINO_ENABLE
      // component: () => import('@/pages/affiliatePage/affiliateRevenue_main.vue')
      component: () => import('@/pages/affiliatePage/affiliateRevenue.vue')
    },
    {
      path: 'member/affiliate/withdraw',
      name: 'affiliate/withdraw',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/affiliatePage/affiliateWithdrawPage.vue')
    },
    {
      path: 'member',
      name: 'member',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      redirect: 'member/main'
    },
    // {
    //   path: 'register-verify/:id',
    //   name: 'register-verify',
    //   meta: {
    //     requiresAuth: false,
    //     isLottoPage: false,
    //     isGamePage: false,
    //     isNoRequestFooter: true
    //   },
    //   component: () => import('@/pages/registerPage/registerPage.vue')
    // },
    // {
    //   path: 'register-verify',
    //   name: 'register-verify',
    //   meta: {
    //     requiresAuth: false,
    //     isLottoPage: false,
    //     isGamePage: false,
    //     isNoRequestFooter: true
    //   },
    //   component: () => import('@/pages/registerPage/registerPage.vue')
    // },
    {
      path: 'aff/:userAg',
      name: 'aff/agent',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      // component: () => import('@/pages/registerAgentPage/registerAgPage.vue')
      component: () => import('@/pages/registerPage/registerForm.vue')
    },
    {
      path: 'register-verify/:id',
      name: 'register-verify',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/registerPage/registerForm.vue')
    },
    {
      path: 'register-verify',
      name: 'register-verify',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/registerPage/registerForm.vue')
    },
    {
      path: 'register-form/agent/:userAg',
      name: 'register-form/agent',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/registerAgentPage/registerAgForm.vue')
    },
    {
      path: 'register-success',
      name: 'register-success',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/registerPage/registerSuccess.vue')
    },
    {
      path: 'member/deposit',
      name: 'member/deposit',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => unavailableTime() ? import('@/pages/unavailablePage/unavailablePage.vue') : import('@/pages/depositPage/depositPage.vue')
    },
    {
      path: 'member/depositP',
      name: 'member/deposit',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      // component: () => unavailableTime() ? import('@/pages/unavailablePage/unavailablePage.vue') : import('@/pages/depositPage2/depositPage.vue')
      component: () => unavailableTime() ? import('@/pages/unavailablePage/unavailablePage.vue') : import('@/pages/depositPage2/depositPageV2.vue')
    },
    {
      path: 'member/deposit/confirmdeposit',
      name: 'member/deposit/confirmdeposit',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/depositPage/confirmDepositPage.vue')
    },
    {
      path: 'member/withdraw',
      name: 'member/withdraw',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => unavailableTime() ? import('@/pages/unavailablePage/unavailablePage.vue') : import('@/pages/withdrawPage/withdrawPage.vue')
    },
    {
      path: 'member/unavailablewithdraw',
      name: 'member/unavailablewithdraw',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/withdrawPage/unavailableWithdrawPage.vue')
    },
    {
      path: 'member/settings/bank',
      name: 'member/settings/bank',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/settingBankPage/settingBankPage.vue')
    },
    {
      path: 'member/vip',
      name: 'member/vip',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/vipPage/vipPage.vue')
    },
    {
      path: 'member/gem',
      name: 'member/gem',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/vipPage/gemPage.vue')
    },
    {
      path: 'forgot-verify',
      name: 'forgot-verify',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/forgotPage/verifyPinPage.vue')
    },
    {
      path: 'reset',
      name: 'reset',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/forgotPage/resetPassPage.vue')
    }
    ]
  }]
}

router.beforeEach((to, from, next) => {
  // const { title, description, keywords } = to.meta
  // const defaultTitle = process.env.VUE_APP_TITLE
  // const defaultDescription = process.env.VUE_APP_DESCRIPTION
  // const defaultKey = process.env.VUE_APP_KEYWORD

  // console.log('-----------------------')
  // console.log(typeof metaSeo[process.env.VUE_APP_AG_USER])
  let defaultTitle = ''
  let defaultDescription = ''
  let defaultKey = ''
  if (typeof to.meta.seoName === 'string' && typeof metaSeo[process.env.VUE_APP_AG_USER] === 'object') {
    defaultTitle = metaSeo[process.env.VUE_APP_AG_USER][to.meta.seoName].title || process.env.VUE_APP_TITLE
    defaultDescription = metaSeo[process.env.VUE_APP_AG_USER][to.meta.seoName].description || process.env.VUE_APP_DESCRIPTION
    defaultKey = metaSeo[process.env.VUE_APP_AG_USER][to.meta.seoName].keywords || process.env.VUE_APP_KEYWORD
  } else {
    defaultTitle = process.env.VUE_APP_TITLE
    defaultDescription = process.env.VUE_APP_DESCRIPTION
    defaultKey = process.env.VUE_APP_KEYWORD
  }

  document.title = defaultTitle
  const descriptionElement = document.querySelector('head meta[name="description"]')
  descriptionElement.setAttribute('content', defaultDescription)
  const descriptionElementKey = document.querySelector('head meta[name="keywords"]')
  descriptionElementKey.setAttribute('content', defaultKey || defaultKey)

  const OgTitle = document.querySelector('head meta[property="og:title"]')
  OgTitle.setAttribute('content', defaultTitle)
  const OgDescription = document.querySelector('head meta[property="og:description"]')
  OgDescription.setAttribute('content', defaultDescription)

  const isGamePermission = Number(store.state.authModule.isGamePermission)
  if (to.matched.some(route => route.meta.requiresAuth) || (to.meta.requiresAuth === null)) {
    if (store.state.authModule.isMember || (to.meta.requiresAuth === null)) {
      if (to.matched.some(route => route.meta.isLottoPage)) {
        if (isGamePermission === 0 || isGamePermission === 1) {
          next()
        } else {
          next('/')
        }
      } else if (to.matched.some(route => route.meta.isGamePage)) {
        if (isGamePermission === 0 || isGamePermission === 2) {
          next()
        } else {
          next('/')
        }
      } else {
        next()
      }
    } else {
      console.log('------ to welcome 1')
      next('/welcome')
    }
  } else {
    if ((to.name === 'forgot-verify' || to.name === 'reset') && from.name === null) {
      console.log('------ to welcome 2')
      next('/welcome')
    } else {
      store.state.authModule.isMember && !store.state.registerModule.registerFlag
        ? next('/')
        : next()
    }
  }
})

export default router
